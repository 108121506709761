import React, { useState } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavDropdown } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Logo from '../assets/images/logo.svg';
import Roadmap from '../assets/pdf/2024-MBD-Roadmap.pdf';
import { Link, NavLink, useNavigate } from 'react-router-dom';

const Header = ({ position }) => {
    const navigate = useNavigate()
    const [show, setShow] = useState(false);
    const showDropdown = (e) => {
        setShow(!show);
    }
    const hideDropdown = e => {
        setShow(false);
    }

    const [show1, setShow1] = useState(false);
    const showDropdown1 = (e) => {
        setShow1(!show);
    }
    const hideDropdown1 = e => {
        setShow1(false);
    }

    return (
        <Navbar key="lg" expand="lg" className={position}>
            <Container>
                <a className="navbar-brand"><img src={Logo} alt="Logo" onClick={()=>{
                    navigate("/")
                    window.location.reload();
                }} /></a>
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} />
                <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-lg`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
                    placement="end"
                >
                    <Offcanvas.Header className='justify-content-end' closeButton>

                    </Offcanvas.Header>
                    <Offcanvas.Body className='justify-content-lg-end'>
                        <Nav className="justify-content-end flex-grow-1 pe-3">
                            {/* <Nav.Link href="#action1">Exchange</Nav.Link> */}
                            <NavDropdown
                                title="Exchange"
                                id={`offcanvasNavbarDropdown-expand-xl`}
                                className='navdropdown-style'
                                show={show}
                                onMouseEnter={showDropdown}
                                onMouseLeave={hideDropdown}
                            >
                                <NavLink className='dropdown-item' target='_blank' to="https://app.uniswap.org/explore/tokens/ethereum/0xaaf449bf8a33a32575c31ba8cbb90612dd95acfa">Uniswap</NavLink>
                                <NavLink className='dropdown-item' target='_blank' to="https://www.mexc.com/login?inviteCode=1caSw">
                                    MEXC
                                </NavLink>
                                <NavLink className='dropdown-item' target='_blank' to="https://hybridex.co/">HybriDEX</NavLink>

                            </NavDropdown>
                            {/* <Nav.Link href="#action2">AI Studio</Nav.Link> */}
                            {/* <Nav.Link href="#action1">Docs</Nav.Link> */}

                            <NavDropdown
                                title="Docs"
                                id={`offcanvasNavbarDropdown-expand-xl`}
                                className='navdropdown-style'
                                show={show1}
                                onMouseEnter={showDropdown1}
                                onMouseLeave={hideDropdown1}
                            >
                                <NavLink className='dropdown-item' target='_blank' to={Roadmap}>Roadmap</NavLink>
                                <NavLink className='dropdown-item' target='_blank' to="https://github.com/ContractWolf/KYC-certificates/blob/main/2024-KYC/KYC_MBD_Financials2024.jpg">Doxxed Team</NavLink>
                                <NavLink className='dropdown-item' target='_blank' to="https://github.com/ContractWolf/smart-contract-audits/blob/main/ContractWolf_Audit_MBD_Financials.pdf">Smart Contract Audit</NavLink>
                                <NavLink className='dropdown-item' target='_blank' to="https://github.com/orgs/MBD-Financials/repositories">GitHub</NavLink>
                                <NavLink className='dropdown-item' target='_blank' to="https://docs.mbdfinancials.com/">White Paper</NavLink>
                                <NavLink className='dropdown-item' target='_blank' to="https://mbdfinancials.substack.com/">Dev Logbook</NavLink>
                                <NavLink className='dropdown-item' to="/terms">Terms &#038; Conditions</NavLink>
                                <NavLink className='dropdown-item' to="/disclaimer">Disclaimer</NavLink>
                                <NavLink className='dropdown-item' to="/privacy-policy">Privacy Policy</NavLink>
                            </NavDropdown>
                            <NavLink className="nav-link" to="/tokenomics">Token</NavLink>
                            <NavLink className="nav-link" target='_blank' to="https://medium.com/@mbd_financials">News</NavLink>
                            <NavLink className="nav-link" to="/team-advisors">Team/Advisors</NavLink>
                            <NavLink className="nav-link" to="/partner-with-us">Partner with us</NavLink>
                            <NavLink className="nav-link" to="/our-story">Our Story</NavLink>
                            {/* <NavDropdown
                        title="Dropdown"
                        id={`offcanvasNavbarDropdown-expand-xl`}
                    >
                        <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                        <NavDropdown.Item href="#action4">
                            Another action
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#action5">
                            Something else here
                        </NavDropdown.Item>
                    </NavDropdown> */}
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
    )
}

export default Header
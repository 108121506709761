import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import InnerOutlet from './Component/InnerOutlet';
const PartnerWithUs = lazy(() => import('./Pages/PartnerWithUs'));
const News = lazy(() => import('./Pages/News'));
const Tokenomics = lazy(() => import('./Pages/Tokenomics'));
const TeamAdvisors = lazy(() => import('./Pages/TeamAdvisors'));
const OurStory = lazy(() => import('./Pages/OurStory'));
const Privacy = lazy(() => import('./Pages/Privacy'));
const Disclaimer = lazy(() => import('./Pages/Disclaimer'));
const Terms = lazy(() => import('./Pages/Terms'));

const Landing = lazy(() => import('./Pages/Landing'));

function App() {
  return (
    <>
      <Suspense fallback={<span>&nbsp;</span>}>

      <Routes>
        <Route path="/" element={<InnerOutlet />}>
          <Route index element={<Landing />} />
          <Route path="/news" element={<News/>} />
          <Route path="/tokenomics" element={<Tokenomics/>} />
          <Route path="/team-advisors" element={<TeamAdvisors/>} />
          <Route path="/our-story" element={<OurStory/>} />
          <Route path="/privacy-policy" element={<Privacy/>} />
          <Route path="/disclaimer" element={<Disclaimer/>} />
          <Route path="/terms" element={<Terms/>} />
          <Route path="/partner-with-us" element={<PartnerWithUs/>} />
        </Route>
      </Routes>
      </Suspense>

    </>
  );
}

export default App;

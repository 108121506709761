import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { Outlet, useLocation } from 'react-router-dom'

const InnerOutlet = () => {
  let location = useLocation();
  location = location.pathname;
  //console.log(location)
  return (
    <>
        <Header position={location == '/'  ? 'fixed-top' : 'sticky-top'} />
          <Outlet/>
        <Footer/>
    </>
  )
}

export default InnerOutlet;
import React, { useState } from 'react'
import facebook from '../assets/images/social/facebook.svg';
import Instagram from '../assets/images/social/instagram.svg';
import Linkedin from '../assets/images/social/linkedin.svg';
import Telegram from '../assets/images/social/telegram.svg';
import Twitter from '../assets/images/social/twitter.svg';
import Youtube from '../assets/images/social/youtube.svg';
import Logo from '../assets/images/logo.svg';
import Roadmap from '../assets/pdf/2024-MBD-Roadmap.pdf';
import { Link, NavLink } from 'react-router-dom';
import { NavDropdown } from 'react-bootstrap';
const Footer = () => {
    const [show, setShow] = useState(false);
    const showDropdown = (e) => {
        setShow(!show);
    }
    const hideDropdown = e => {
        setShow(false);
    }
    const [show1, setShow1] = useState(false);
    const showDropdown1 = (e) => {
        setShow1(!show);
    }
    const hideDropdown1 = e => {
        setShow1(false);
    }
    return (
        <footer className="text-center footer-border overflow-visible">
            <div className="text-center pt-3 py-lg-5 my-lg-2 py-3 my-3 d-flex flex-column gap-2">
                <Link to="/"><img className="img-fluid footer_logo" src={Logo} height="55" alt="MBD"
                    title="MBD" /></Link>
                {/* <span className='text-xm'>Lorem ipsum dolor sit amet</span> */} </div>
            <div className="pb-3 pb-md-4 pb-lg-5">
                <div className="inline-items">
                    <NavDropdown
                        title="Exchange"
                        id={`offcanvasNavbarDropdown-expand-xl`}
                        className='navdropdown-style link'
                        show={show}
                        onMouseEnter={showDropdown}
                        onMouseLeave={hideDropdown}
                    >
                        <NavLink className='dropdown-item' target='_blank' to="https://app.uniswap.org/explore/tokens/ethereum/0xaaf449bf8a33a32575c31ba8cbb90612dd95acfa">Uniswap</NavLink>
                        <NavLink className='dropdown-item' target='_blank' to="https://www.mexc.com/login?inviteCode=1caSw">
                            MEXC
                        </NavLink>
                        <NavLink className='dropdown-item' target='_blank' to="https://hybridex.co/">HybriDEX</NavLink>

                    </NavDropdown>
                    {/* <NavLink to="/" className="link">AI Studio</NavLink> */}
                    <NavDropdown
                        title="Docs"
                        id={`offcanvasNavbarDropdown-expand-xl`}
                        className='navdropdown-style link'
                        show={show1}
                        onMouseEnter={showDropdown1}
                        onMouseLeave={hideDropdown1}
                    >
                        <NavLink className='dropdown-item' target='_blank' to={Roadmap}>Roadmap</NavLink>
                        <NavLink className='dropdown-item' target='_blank' to="https://github.com/ContractWolf/KYC-certificates/blob/main/2024-KYC/KYC_MBD_Financials2024.jpg">Doxxed Team</NavLink>
                        <NavLink className='dropdown-item' target='_blank' to="https://github.com/ContractWolf/smart-contract-audits/blob/main/ContractWolf_Audit_MBD_Financials.pdf">Smart Contract Audit</NavLink>
                        <NavLink className='dropdown-item' target='_blank' to="https://github.com/orgs/MBD-Financials/repositories">GitHub</NavLink>
                        <NavLink className='dropdown-item' target='_blank' to="https://docs.mbdfinancials.com/">White Paper</NavLink>
                        <NavLink className='dropdown-item' target='_blank' to="https://mbdfinancials.substack.com/">Dev Logbook</NavLink>
                        <NavLink className='dropdown-item' to="/terms">Terms &#038; Conditions</NavLink>
                        <NavLink className='dropdown-item' to="/disclaimer">Disclaimer</NavLink>
                        <NavLink className='dropdown-item' to="/privacy-policy">Privacy Policy</NavLink>
                    </NavDropdown>
                    <NavLink to="/tokenomics" className="nav-link mx-2 mx-md-4">Token</NavLink>
                    <NavLink className="nav-link mx-2 mx-md-4" target='_blank' to="https://medium.com/@mbd_financials">News</NavLink>
                    <NavLink to="/team-advisors" className="nav-link mx-2 mx-md-4">Team/Advisors</NavLink>
                    <NavLink className="nav-link mx-2 mx-md-4" to="/partner-with-us">Partner with us</NavLink>
                    <NavLink to="/our-story" className="nav-link mx-2 mx-md-4">Our Story</NavLink>
                </div>
                <div className="inline-items gap-4 mt-4">
                    <a href="https://www.facebook.com/MBDFinancials" target='_blank' className="social">
                        <img className="img-fluid" src={facebook} alt="Facebook" />
                    </a>
                    <a href="https://twitter.com/MBDFinancials" className="social" target='_blank'>
                        <img className="img-fluid" src={Twitter} alt="Twitter" />
                    </a>
                    <a href="https://www.youtube.com/channel/UCju8ObUSidjgEvevlXc9pVg" className="social" target='_blank'>
                        <img className="img-fluid" src={Youtube} alt="Youtube" />
                    </a>
                    <a href="https://www.instagram.com/mbdfinancials/" className="social" target='_blank'>
                        <img className="img-fluid" src={Instagram} alt="Instagram" />
                    </a>
                    <a href="https://t.me/MBDFinancialsVerification" className="social" target='_blank'>
                        <img className="img-fluid" src={Telegram} alt="Telegram" />
                    </a>
                    <a href="https://www.linkedin.com/company/mbdfinancials/" className="social" target='_blank'>
                        <img className="img-fluid" src={Linkedin} alt="Linkedin" />
                    </a>
                </div>
            </div>
            <div className="copyright py-4"> &copy; Copyright 2024 MBD Financials. All Rights Reserved. </div>
        </footer>
    )
}

export default Footer